import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AdvisoryOrgDetails } from '_types/advisory-org.interface';

import {
  fetchGetAdvisoryOrgDetails,
  fetchGetAllAdvisoryOrgNames,
} from 'store/actions/advisory-org';

interface AdvisoryOrgState {
  advisoryOrgDetails: AdvisoryOrgDetails | null;
  isAdvisoryOrgDetailsLoading: boolean;
  allAdvisoryOrgNames: string[];
  isAllAdvisoryOrgNamesLoading: boolean;
}

export const advisoryOrgSliceInitialState: AdvisoryOrgState = {
  advisoryOrgDetails: null,
  isAdvisoryOrgDetailsLoading: false,
  allAdvisoryOrgNames: [],
  isAllAdvisoryOrgNamesLoading: false,
};

const advisoryOrgSlice = createSlice({
  name: 'advisory-org',
  initialState: advisoryOrgSliceInitialState,
  reducers: {
    resetState: () => advisoryOrgSliceInitialState,
    actionSetAdvisoryOrgDetails: (
      state,
      action: PayloadAction<AdvisoryOrgDetails | null>,
    ) => {
      state.advisoryOrgDetails = action.payload;
    },
    actionEditAdvisoryOrgDetails: (
      state,
      action: PayloadAction<Partial<AdvisoryOrgDetails>>,
    ) => {
      state.advisoryOrgDetails = {
        ...state.advisoryOrgDetails!,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAdvisoryOrgDetails.pending, (state) => {
      state.isAdvisoryOrgDetailsLoading = true;
    });
    builder.addCase(fetchGetAdvisoryOrgDetails.fulfilled, (state, action) => {
      state.isAdvisoryOrgDetailsLoading = false;
      state.advisoryOrgDetails = action.payload;
    });
    builder.addCase(fetchGetAdvisoryOrgDetails.rejected, (state) => {
      state.isAdvisoryOrgDetailsLoading = false;
    });
    builder.addCase(fetchGetAllAdvisoryOrgNames.pending, (state) => {
      state.isAllAdvisoryOrgNamesLoading = true;
    });
    builder.addCase(fetchGetAllAdvisoryOrgNames.fulfilled, (state, action) => {
      state.allAdvisoryOrgNames = [...action.payload, 'N/A'];
      state.isAllAdvisoryOrgNamesLoading = false;
    });
    builder.addCase(fetchGetAllAdvisoryOrgNames.rejected, (state) => {
      state.isAllAdvisoryOrgNamesLoading = false;
    });
  },
});

export const {
  actionSetAdvisoryOrgDetails,
  actionEditAdvisoryOrgDetails,
  resetState,
} = advisoryOrgSlice.actions;

export default advisoryOrgSlice;
