import { PlatformLanguages } from '_types';

import { TenantStyling } from './tenant-styling.interface';

export enum PageName {
  HOME = 'home',
  COURSES = 'courses',
  ADVISORS = 'advisors',
  FUNDINGS = 'fundings',
  ABOUTUS = 'aboutus',
  LOGIN_INFO = 'login_info',
  BUSINESS_NEEDS = 'business_needs',
  PROFILE = 'profile',
  BUSINESS_DEMOGRAPHICS = 'business_demographics',
  BUSINESS = 'business',
  ORGANIZATION = 'organization',
  SPECIALITY = 'speciality',
  CLIENTS = 'clients',
  PRIVACY_POLICY = 'privacy_policy',
  SBO_USER_AGREEMENT = 'sbo_user_agreement',
  ADVISOR_USER_AGREEMENT = 'advisor_user_agreement',
  COMMUNITY = 'community',
  SBO_ONBORDING = 'sbo_onboarding',
  ADVISOR_ONBOARDING = 'advisor_onboarding',
  AUTH_SBO = 'auth_sbo',
  AUTH_ADVISORY = 'auth_advisory',
  MFA_SETUP = 'mfa_setup',
  EMAIL_VERIFICATION = 'email_verification',
  RESET_PASSWORD = 'reset_password',
  ADVISORY_ORGANIZATION = 'advisory_organization',
  GLOSSARY = 'glossary',
}

export enum TenantTypeEnum {
  NXST = 'nxst',
  NYC_FUNDS_FINDER = 'nyc_funds_finder',
  VERIZON = 'verizon',
}

export enum TenantTranslationPrefixEnum {
  NXST = 'nxst',
  NYC_FUNDS_FINDER = 'nyc_funds_finder',
  VERIZON = 'verizon',
}

export interface TenantFeatureFlags {
  id: number;
  courses: boolean;
  sessions: boolean;
  users: boolean;
  fundings: boolean;
  community: boolean;
  glossary: boolean;
  aboutUs: boolean;
  geographyLabel: boolean;
  languageSwitch: boolean;
  isRedirectedFromVerizonCheckEnabled: boolean;
}

export interface LmsSettings {
  departmentId: string;
  tenantUrl: string;
}

export interface ZendeskSettings {
  zendeskKey: string;
  zendeskUrl: string;
}
export type TagType = Record<string, string>;

export type PlatformPageData = {
  title: string;
  metaData: Record<string, TagType[]>;
};

type PlatformPagesData = {
  [key in PageName]?: PlatformPageData;
};
export type PageAttributesSettings = {
  favIcon: string;
  pageData: PlatformPagesData;
};

export type BusinessTopicDataType = {
  value: string;
  iconKey: string;
  titleTranslationKey: string;
  hintTranslationKey: string | null;
};

export enum TenantLegalDocumentTypeEnum {
  PRIVACY_POLICY = 'privacy-policy',
  SBO_AGREEMENT = 'sbo-agreement',
  ADVISOR_AGREEMENT = 'advisor-agreement',
}

export type LegalDocumentContent = Partial<Record<PlatformLanguages, string>>;

export type TenantLegalDocument = {
  id: number;
  type: TenantLegalDocumentTypeEnum;
  content: LegalDocumentContent;
  version: string;
  releaseDate: string; // ISO string date only
};

export type TenantLegalDocumentSmall = Omit<TenantLegalDocument, 'content'>;

export type TenantLegalDocumentTypeToLatestDocument = Record<
  TenantLegalDocumentTypeEnum,
  TenantLegalDocument
>;

export interface TenantSettings {
  tenantName: string;
  tenantType: TenantTypeEnum;
  tenantTranslationPrefix: TenantTranslationPrefixEnum;
  languagesAvailable: PlatformLanguages[];

  tenantStyling: TenantStyling | null;

  tenantFeatureFlags: TenantFeatureFlags | null;

  latestTenantLegalDocuments: TenantLegalDocumentTypeToLatestDocument | null;

  lmsSettings: LmsSettings | null;

  pageAttributes: PageAttributesSettings;

  zendeskSettings: ZendeskSettings | null;

  sboBusinessNeedsAvailable: BusinessTopicDataType[];

  advisorSkillsAvailable: BusinessTopicDataType[];
}
