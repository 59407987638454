import { createSlice } from '@reduxjs/toolkit';

import { CoursesFilterSettings, CourseType } from '_types/courses.interface';

import {
  fetchNewHomeCoursesCards,
  fetchNewCoursesCards,
  fetchVendorList,
  fetchCourseFilterSettings,
} from 'store/actions/courses';

interface NewCoursesState {
  home: {
    isLoading: boolean;
    count: number | null;
    cards: CourseType[];
  };
  courses: {
    count: number;
    isLoading: boolean;
    cards: CourseType[];
  };
  vendorList: {
    isLoading: boolean;
    data: string[] | null;
  };
  isCoursesFilterDetailsLoading: boolean;
  coursesFilterDetails: CoursesFilterSettings;
}

export const coursesSliceInitialState: NewCoursesState = {
  home: {
    count: null,
    isLoading: true,
    cards: [],
  },
  courses: {
    count: 0,
    isLoading: true,
    cards: [],
  },
  vendorList: {
    isLoading: false,
    data: null,
  },
  isCoursesFilterDetailsLoading: false,
  coursesFilterDetails: {} as CoursesFilterSettings,
};

const coursesSlice = createSlice({
  name: 'course',
  initialState: coursesSliceInitialState,
  reducers: {
    resetState: () => coursesSliceInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewCoursesCards.pending, (state) => {
      state.courses.isLoading = true;
      state.courses.cards = [];
      state.courses.count = 0;
    });
    builder.addCase(fetchNewCoursesCards.fulfilled, (state, action) => {
      state.courses.cards = action.payload.courses;
      state.courses.count = action.payload.totalItems;
      state.courses.isLoading = false;
    });
    builder.addCase(fetchNewCoursesCards.rejected, (state) => {
      state.courses.isLoading = false;
    });
    builder.addCase(fetchNewHomeCoursesCards.pending, (state) => {
      state.home.isLoading = true;
    });
    builder.addCase(fetchNewHomeCoursesCards.fulfilled, (state, action) => {
      state.home.count = action.payload.count;
      state.home.cards = action.payload.courses;
      state.home.isLoading = false;
    });
    builder.addCase(fetchNewHomeCoursesCards.rejected, (state) => {
      state.home.isLoading = false;
    });
    builder.addCase(fetchVendorList.pending, (state) => {
      state.vendorList.isLoading = true;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorList.data = action.payload;
      state.vendorList.isLoading = false;
    });
    builder.addCase(fetchVendorList.rejected, (state) => {
      state.vendorList.isLoading = false;
    });
    builder.addCase(fetchCourseFilterSettings.pending, (state) => {
      state.isCoursesFilterDetailsLoading = true;
    });
    builder.addCase(fetchCourseFilterSettings.fulfilled, (state, action) => {
      state.isCoursesFilterDetailsLoading = false;
      state.coursesFilterDetails = action.payload;
    });
    builder.addCase(fetchCourseFilterSettings.rejected, (state, _action) => {
      state.isCoursesFilterDetailsLoading = false;
    });
  },
});

export const { resetState } = coursesSlice.actions;

export default coursesSlice;
