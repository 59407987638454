import type {
  AdvisoryOrgDetails,
  AdvisoryOrgSearchType,
} from '_types/advisory-org.interface';

import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const advisoryOrgService = {
  getAdvisoryOrgDetails: (id: number) =>
    axiosApiInstance
      .get<AdvisoryOrgDetails>(`${baseUrl}/api/v1/advisory-org/details/${id}`)
      .then(({ data }) => data),
  getAllAdvisoryOrgNames: () =>
    axiosApiInstance
      .get<string[]>(`${baseUrl}/api/v1/advisory-org/org-names`)
      .then(({ data }) => data),
  getAdvisoryOrgList: (body: AdvisoryOrgSearchType) =>
    axiosApiInstance
      .post(`${baseUrl}/api/v1/advisory-org/search-advisor-org`, body)
      .then(({ data }) => data),
};

export default advisoryOrgService;
