import React, { memo } from 'react';

interface CloseEyeIconProps {
  className?: string;
}
const CloseEyeIcon: React.FC<CloseEyeIconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5085 14.5092L17.4994 17.5M11.5619 15.6875C11.0468 15.7849 10.5236 15.8337 9.99937 15.8333C6.26771 15.8333 3.10937 13.3808 2.04688 10C2.33293 9.09008 2.77354 8.23622 3.34937 7.47583L11.5619 15.6875ZM8.23104 8.2325C8.69992 7.76362 9.33586 7.5002 9.99896 7.5002C10.6621 7.5002 11.298 7.76362 11.7669 8.2325C12.2358 8.70138 12.4992 9.33732 12.4992 10.0004C12.4992 10.6635 12.2358 11.2995 11.7669 11.7683L8.23104 8.2325ZM8.23104 8.23167L11.766 11.7667L8.23104 8.23167ZM8.23271 8.23333L5.49104 5.49167L8.23271 8.23333ZM11.7677 11.7683L14.5094 14.51L11.7677 11.7683ZM2.49937 2.5L5.49104 5.49167L2.49937 2.5ZM5.49104 5.49167C6.83467 4.62464 8.40029 4.1645 9.99937 4.16667C13.731 4.16667 16.8894 6.61917 17.9519 10C17.3652 11.8587 16.1472 13.4538 14.5085 14.5092L5.49104 5.49167Z"
      stroke="#676767"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(CloseEyeIcon);
