import misc from './misc.json';
import onboarding from './onboarding.json';
import auth from './auth.json';
import myAccount from './myAccount.json';
import advisor from './advisor.json';
import advisory_org from './advisory_org.json';
import validation from './validation.json';
import time from './time.json';
import notifications from './notifications.json';
import about_us from './about_us.json';
import funding from './funding.json';
import course from './course.json';
import glossary from './glossary.json';

export const resourcesEnNycFundsFinder = {
  misc,
  auth,
  onboarding,
  myAccount,
  advisor,
  advisory_org,
  validation,
  time,
  notifications,
  about_us,
  funding,
  course,
  glossary,
};
