import clsx from 'clsx';
import React, { memo, useMemo } from 'react';

import { ProfileFieldErrorType } from '_types/profile.interface';

import AddButtonIcon from '../Icons/AddButtonIcon';
import CheckMarkIcon from '../Icons/CheckMarkIcon';

import './style.scss';

interface BulletsRequiredListProps<T = ProfileFieldErrorType> {
  errorsContainerTitle?: string;
  checksList: T[];
  errorsList?: T | T[];
  className?: string;
}

const BulletsRequiredList: React.FC<BulletsRequiredListProps> = ({
  errorsContainerTitle,
  checksList,
  errorsList,
  className,
}) => {
  const formattedErrorList = useMemo(() => {
    if (!errorsList) {
      return [];
    }

    if (typeof errorsList === 'string') {
      return [errorsList];
    }

    return errorsList;
  }, [errorsList]);

  const outOfCheckListErrors = useMemo(
    () => formattedErrorList?.filter((error) => !checksList.includes(error)),
    [formattedErrorList, checksList],
  );

  const errorsListComponent = useMemo(
    () =>
      checksList?.map((errorTextToCheck, index) => {
        const hasError = formattedErrorList?.includes(errorTextToCheck);

        return (
          <div key={index} className="bullets_required__list_item">
            {hasError ? (
              <AddButtonIcon className="icon" />
            ) : (
              <CheckMarkIcon className="icon" />
            )}

            <p>{errorTextToCheck}</p>
          </div>
        );
      }),
    [checksList, formattedErrorList],
  );

  return (
    <div className={clsx('bullets_required', className)}>
      <div className="bullets_required__triangle">
        <div />
        <div />
        <div />
      </div>

      {outOfCheckListErrors?.length ? (
        <p className="bullets_required__text additional-error">
          {outOfCheckListErrors[0]}
        </p>
      ) : null}

      <p className="bullets_required__text">{errorsContainerTitle}</p>

      <div className="bullets_required__list">{errorsListComponent}</div>
    </div>
  );
};

export default memo(BulletsRequiredList);
