import { ReactNode } from 'react';

import type { BaseModalProps } from 'app/components/Modals/BaseModal';
import type { ButtonSizeChoices } from 'app/components/Button';

import { GrantItemType, LoanItemType } from './funding.interface';

export enum LoginFormType {
  login = 'login',
  loginToContinue = 'loginToContinue',
  welcomeBack = 'welcomeBack',
  reauthenticate = 'reauthenticate',
  loginToAbsorb = 'loginToAbsorb',
}

export interface LoginModalType {
  formType: LoginFormType;
  onLoginSuccessful?: () => void | Promise<void>;
}

export enum ResetPasswordModalTitleType {
  profile = 'profile',
  auth = 'auth',
}

export interface ShowResetPasswordModalType {
  type: ResetPasswordModalTitleType;
  data?: {
    resetPasswordToken: string;
  };
}

export enum ShowProfileSuccessPopupType {
  password = 'password',
  phone_number_change_success = 'phone_number_change_success',
  mfa_setup_success = 'mfa_setup_success',
  advisor_invitation_successful = 'advisor_invitation_successful',
  info = 'info',
  loader = 'loader',
  error = 'error',
}

interface ModalClassNamesType {
  modalContainer?: string;
  modal?: string;
  modalInnerContainer?: string;
  modalHeader?: string;
  modalTitle?: string;
  modalContent?: string;
  modalFooter?: string;
  modalPhoneUpperLine?: string;
}

export interface InfoModalType {
  closeOnBackgroundClick?: boolean;
  onModalClose?: Function;
  closeButtonText?: string;
  closeButtonSize?: ButtonSizeChoices;
  headerIcon?: ReactNode;
  modalTitle?: string;
  classNames?: ModalClassNamesType;
  content?: ReactNode;
  modalCloseDisabled?: boolean;
}

export type GeneralModalType = {
  content?: ReactNode;
} & BaseModalProps;

export interface NonDigitalFundingInfoModalType {
  loanName: string;
  mainColor: string;
  description: string | null;
  fundingProviderUrl: string | null;
  phone: string | null;
  email: string | null;
  headerIcon?: ReactNode | null;
}

export interface ProfileMissingDataModalType {
  missingFields: string[];
}

export interface ProfileMissingDataSuccessModal {}

export interface ExternalUrlConsentModalType {
  externalUrl: string;
  target?: HTMLAnchorElement['target'];
  rel?: HTMLAnchorElement['rel'];
  title?: string;
  acceptButtonText?: string;
}

export type FundingCardExpandedViewModalType = {
  fundingType: 'Grant' | 'Loan';
  // eslint-disable-next-line no-use-before-define
  fundingValue: FundingCardExpandedViewValue;
};

export type FundingCardExpandedViewValue =
  FundingCardExpandedViewModalType['fundingType'] extends 'Grant'
    ? GrantItemType
    : FundingCardExpandedViewModalType['fundingType'] extends 'Loan'
      ? LoanItemType
      : never;
