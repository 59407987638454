import { createAsyncThunk } from '@reduxjs/toolkit';

import { CourseAPIQueryParams } from '_types/courses.interface';

import coursesService from '_services/coursesService';

export const fetchNewHomeCoursesCards = createAsyncThunk(
  'courses/fetchNewHomeCoursesCards',
  async (query: CourseAPIQueryParams, { rejectWithValue }) => {
    try {
      return await coursesService.getNewHomeCourses({
        offset: query.offset,
        limit: query.limit,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const fetchNewCoursesCards = createAsyncThunk(
  'courses/fetchNewCoursesCards',
  async (query: CourseAPIQueryParams, { rejectWithValue }) => {
    try {
      return await coursesService.getNewCourses({
        offset: query.offset,
        limit: query.limit,
        order: query.order,
        topicArea: query.topicArea,
        industry: query.industry,
        courseType: query.courseType,
        minLength: query.minLength,
        maxLength: query.maxLength,
        certification: query.certification,
        audience: query.audience,
        vendor: query.vendor,
      } as CourseAPIQueryParams);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchVendorList = createAsyncThunk(
  'courses/fetchVendorList',
  async (_, { rejectWithValue }) => {
    try {
      return await coursesService.getVendorList();
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCourseFilterSettings = createAsyncThunk(
  'courses/fetchCourseFilterSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coursesService.getCoursesFilterSettings();
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
