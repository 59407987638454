import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { unset } from 'lodash';

import {
  ProfileAdvisoryOrgInfo,
  ProfileAdvisor,
  ProfileInfoType,
  ProfileSBOInfo,
  ShowUnsavedChangesPrompt,
  HaveProfileFieldsInnerErrorsObject,
  ProfileFieldsErrorsObject,
} from '_types/profile.interface';

import {
  fetchPutOnboardingData,
  fetchPutOnboardingProgress,
} from 'store/actions/onboarding';
import {
  fetchAddAdvisorInvitations,
  fetchDeleteAdvisor,
  fetchGetProfileInfo,
  fetchPatchProfileInfo,
  fetchSetUserCookieConsent,
} from 'store/actions/profile';
import {
  fetchAcceptMultipleTenantLegalDocuments,
  fetchChangeEmail,
  fetchPatchUser,
  fetchRegistration,
} from 'store/actions/user';

interface ProfileState {
  profileInfo: ProfileInfoType | null;
  prevProfileInfo: ProfileInfoType | null;
  error: any;
  loader: boolean;
  resetEmailError: any;
  isResetEmailLoading: boolean;
  dataValidationErrors: ProfileFieldsErrorsObject<ProfileInfoType>;
  missingDataValidationErrors: Record<string, any>;
  dataValidationSchema: any;
  haveProfileFieldsInnerErrorsObject: HaveProfileFieldsInnerErrorsObject<ProfileInfoType>;
  profileFieldsExternalErrorsObject: ProfileFieldsErrorsObject<ProfileInfoType>;
  showUnsavedChangesPrompt: ShowUnsavedChangesPrompt | null;
}

export const profileSliceInitialState: ProfileState = {
  profileInfo: null,
  prevProfileInfo: null,
  error: '',
  loader: false,

  resetEmailError: false,
  isResetEmailLoading: false,

  dataValidationSchema: null,
  dataValidationErrors: {},
  missingDataValidationErrors: {},

  showUnsavedChangesPrompt: null,

  haveProfileFieldsInnerErrorsObject: {},
  profileFieldsExternalErrorsObject: {},
};

const setError = (state: ProfileState, action: PayloadAction<any>) => {
  state.loader = false;
  state.error = action.payload;
};

const setErrorAndProfileFieldsExternalErrorsObject = (
  state: ProfileState,
  action: PayloadAction<any>,
) => {
  setError(state, action);

  const errorsObjectFromResponseData = action.payload?.response?.data?.errors;
  if (errorsObjectFromResponseData) {
    state.profileFieldsExternalErrorsObject = errorsObjectFromResponseData;
  }
};

const filterNewAdvisorsList = (profileInfo, typeAdvisors, email) =>
  profileInfo.advisoryOrgData[typeAdvisors]?.filter(
    (elem) => elem.email !== email,
  );

const profileSlice = createSlice({
  name: 'profile',
  initialState: profileSliceInitialState,
  reducers: {
    resetState: () => profileSliceInitialState,
    actionSetProfileInfo: (
      state,
      action: PayloadAction<ProfileInfoType | null>,
    ) => {
      state.profileInfo = action.payload;
    },
    actionEditProfileInfo: (
      state,
      action: PayloadAction<Partial<ProfileInfoType>>,
    ) => {
      state.profileInfo = {
        ...state.profileInfo!,
        ...action.payload,
      };
    },
    actionSetSboInfo: (state, action: PayloadAction<ProfileSBOInfo>) => {
      state.profileInfo!.sboData = action.payload;
    },
    actionEditSboInfo: (
      state,
      action: PayloadAction<Partial<ProfileSBOInfo>>,
    ) => {
      state.profileInfo!.sboData = {
        ...state.profileInfo!.sboData!,
        ...action.payload,
      };
    },
    actionEditCurrentAndPrevSboInfo: (
      state,
      action: PayloadAction<Partial<ProfileSBOInfo>>,
    ) => {
      state.profileInfo!.sboData = {
        ...state.profileInfo!.sboData!,
        ...action.payload,
      };

      state.prevProfileInfo!.sboData = {
        ...state.prevProfileInfo!.sboData!,
        ...action.payload,
      };
    },
    actionSetAdvisor: (state, action: PayloadAction<ProfileAdvisor>) => {
      state.profileInfo!.advisorData = action.payload;
    },
    actionEditAdvisor: (
      state,
      action: PayloadAction<Partial<ProfileAdvisor>>,
    ) => {
      state.profileInfo!.advisorData = {
        ...state.profileInfo!.advisorData!,
        ...action.payload,
      };
    },
    actionEditCurrentAndPrevAdvisor: (
      state,
      action: PayloadAction<Partial<ProfileAdvisor>>,
    ) => {
      state.profileInfo!.advisorData = {
        ...state.profileInfo!.advisorData!,
        ...action.payload,
      };

      state.prevProfileInfo!.advisorData = {
        ...state.prevProfileInfo!.advisorData!,
        ...action.payload,
      };
    },
    actionSetAdvisoryOrgInfo: (
      state,
      action: PayloadAction<ProfileAdvisoryOrgInfo>,
    ) => {
      state.profileInfo!.advisoryOrgData = action.payload;
    },
    actionEditAdvisoryOrgInfo: (
      state,
      action: PayloadAction<Partial<ProfileAdvisoryOrgInfo>>,
    ) => {
      state.profileInfo!.advisoryOrgData = {
        ...state.profileInfo!.advisoryOrgData!,
        ...action.payload,
      };
    },
    actionEditAdvisoryOrgPrevInfo: (
      state,
      action: PayloadAction<Partial<ProfileAdvisoryOrgInfo>>,
    ) => {
      state.prevProfileInfo!.advisoryOrgData = {
        ...state.prevProfileInfo!.advisoryOrgData!,
        ...action.payload,
      };
    },
    actionChangeToPrevProfileInfo: (state) => {
      state.profileInfo = { ...state.prevProfileInfo! };
    },
    actionChangeProfileDataValidationErrors: (
      state,
      action: PayloadAction<Record<string, any>>,
    ) => {
      state.dataValidationErrors = action.payload;
    },
    actionRemoveProfileDataValidationErrors: (
      state,
      action: PayloadAction<string>,
    ) => {
      const path = action.payload;
      unset(state.dataValidationErrors, path);
    },
    actionChangeProfileMissingDataValidationErrors: (
      state,
      action: PayloadAction<Record<string, any>>,
    ) => {
      state.missingDataValidationErrors = action.payload;
    },
    actionChangeProfileError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    actionChangeProfileDataValidationSchema: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.dataValidationSchema = action.payload;
    },
    actionSetShowUnsavedChangesPrompt: (
      state,
      action: PayloadAction<ShowUnsavedChangesPrompt | null>,
    ) => {
      state.showUnsavedChangesPrompt = action.payload;
    },
    actionSetHaveProfileFieldsInnerErrorsObject: (
      state,
      action: PayloadAction<
        HaveProfileFieldsInnerErrorsObject<ProfileInfoType>
      >,
    ) => {
      state.haveProfileFieldsInnerErrorsObject = action.payload;
    },
    actionSetProfileFieldsExternalErrorsObject: (
      state,
      action: PayloadAction<ProfileFieldsErrorsObject<ProfileInfoType>>,
    ) => {
      state.profileFieldsExternalErrorsObject = action.payload;
    },
    actionEditProfileFieldsExternalErrorsObject: (
      state,
      action: PayloadAction<string>,
    ) => {
      unset(state.profileFieldsExternalErrorsObject, action.payload);
    },
    actionClearProfileErrors: (state) => {
      state.error = '';
      state.resetEmailError = false;
      state.dataValidationErrors = {};
      state.missingDataValidationErrors = {};
      state.profileFieldsExternalErrorsObject = {};
      state.haveProfileFieldsInnerErrorsObject = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRegistration.fulfilled, (state) => {
      state.loader = false;
      state.error = '';
    });
    builder.addCase(fetchRegistration.pending, (state) => {
      state.loader = true;
      state.profileFieldsExternalErrorsObject = {};
    });
    builder.addCase(fetchRegistration.rejected, setError);

    builder.addCase(fetchPatchUser.fulfilled, (state, action) => {
      state.loader = false;
      state.error = '';
      state.profileInfo = {
        ...(state.profileInfo || {}),
        ...action.payload,
      };
      state.prevProfileInfo = {
        ...(state.prevProfileInfo || {}),
        ...action.payload,
      };
    });
    builder.addCase(fetchPatchUser.pending, (state) => {
      state.loader = true;
      state.profileFieldsExternalErrorsObject = {};
    });
    builder.addCase(
      fetchPatchUser.rejected,
      setErrorAndProfileFieldsExternalErrorsObject,
    );

    builder.addCase(fetchChangeEmail.pending, (state) => {
      state.isResetEmailLoading = true;
    });
    builder.addCase(fetchChangeEmail.fulfilled, (state, action) => {
      state.resetEmailError = false;
      state.isResetEmailLoading = false;
      state.error = '';
      state.profileInfo = {
        ...state.profileInfo,
        ...action.payload,
      };
      state.prevProfileInfo = {
        ...state.prevProfileInfo,
        ...action.payload,
      };
    });
    builder.addCase(fetchChangeEmail.rejected, (state, action) => {
      state.isResetEmailLoading = false;
      state.resetEmailError = action.payload;
    });

    builder.addCase(fetchGetProfileInfo.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fetchGetProfileInfo.fulfilled, (state, action) => {
      state.profileInfo = action.payload;
      state.prevProfileInfo = action.payload;
      state.loader = false;
    });
    builder.addCase(fetchGetProfileInfo.rejected, setError);

    builder.addCase(fetchPatchProfileInfo.pending, (state) => {
      state.loader = true;
      state.profileFieldsExternalErrorsObject = {};
    });
    builder.addCase(fetchPatchProfileInfo.fulfilled, (state, action) => {
      state.profileInfo = {
        ...state.profileInfo,
        ...action.payload,
      };
      state.prevProfileInfo = {
        ...state.prevProfileInfo,
        ...action.payload,
      };
      state.loader = false;
      state.error = '';
    });
    builder.addCase(
      fetchPatchProfileInfo.rejected,
      setErrorAndProfileFieldsExternalErrorsObject,
    );

    builder.addCase(
      fetchPutOnboardingData.rejected,
      setErrorAndProfileFieldsExternalErrorsObject,
    );

    builder.addCase(fetchAddAdvisorInvitations.pending, (state) => {
      state.loader = true;
      state.profileFieldsExternalErrorsObject = {};
    });
    builder.addCase(fetchAddAdvisorInvitations.fulfilled, (state, action) => {
      state.loader = false;
      state.profileInfo!.advisoryOrgData!.advisorInvitations =
        state.profileInfo!.advisoryOrgData!.advisorInvitations?.filter(
          (inv) => !!inv.id,
        );
      state.profileInfo!.advisoryOrgData!.advisorInvitations?.push(
        ...action.payload,
      );
      state.prevProfileInfo!.advisoryOrgData!.advisorInvitations =
        state.profileInfo!.advisoryOrgData!.advisorInvitations;
    });
    builder.addCase(fetchAddAdvisorInvitations.rejected, (state, action) => {
      setErrorAndProfileFieldsExternalErrorsObject(state, action);

      const errorsObjectFromResponseData = (action.payload as any)?.response
        ?.data?.errors?.invitationsToAdd;
      if (errorsObjectFromResponseData) {
        (state.profileFieldsExternalErrorsObject as any) = {
          ...state.profileFieldsExternalErrorsObject,
          advisoryOrgData: {
            ...state.profileFieldsExternalErrorsObject?.advisoryOrgData,
            advisorInvitations: errorsObjectFromResponseData,
          },
        };
      }
    });

    builder.addCase(fetchDeleteAdvisor.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fetchDeleteAdvisor.fulfilled, (state, action) => {
      state.loader = false;
      state.error = '';

      const emailDeleted = action.payload;

      const newCurrentAdvisors = filterNewAdvisorsList(
        state.profileInfo,
        'currentAdvisors',
        emailDeleted,
      );
      const newAdvisorInvitations = filterNewAdvisorsList(
        state.profileInfo,
        'advisorInvitations',
        emailDeleted,
      );

      state.profileInfo!.advisoryOrgData!.currentAdvisors = newCurrentAdvisors;
      state.prevProfileInfo!.advisoryOrgData!.currentAdvisors =
        newCurrentAdvisors;
      state.profileInfo!.advisoryOrgData!.advisorInvitations =
        newAdvisorInvitations;
      state.prevProfileInfo!.advisoryOrgData!.advisorInvitations =
        newAdvisorInvitations;
    });
    builder.addCase(fetchDeleteAdvisor.rejected, setError);
    builder.addCase(fetchSetUserCookieConsent.fulfilled, (state, action) => {
      state.profileInfo!.isUserCookieConsent = action.payload.accepted;
      state.prevProfileInfo!.isUserCookieConsent = action.payload.accepted;
    });
    builder.addCase(fetchPutOnboardingProgress.fulfilled, (state) => {
      state.haveProfileFieldsInnerErrorsObject = {};
    });
    builder.addCase(
      fetchAcceptMultipleTenantLegalDocuments.pending,
      (state) => {
        state.loader = true;
      },
    );
    builder.addCase(
      fetchAcceptMultipleTenantLegalDocuments.fulfilled,
      (state, action) => {
        state.loader = false;
        state.profileInfo!.latestTenantLegalDocumentUserAccepts =
          action.payload;
      },
    );
    builder.addCase(fetchAcceptMultipleTenantLegalDocuments.rejected, setError);
  },
});

export const {
  actionSetProfileInfo,
  actionEditProfileInfo,
  actionSetSboInfo,
  actionEditSboInfo,
  actionEditCurrentAndPrevSboInfo,
  actionSetAdvisor,
  actionEditAdvisor,
  actionEditCurrentAndPrevAdvisor,
  actionSetAdvisoryOrgInfo,
  actionEditAdvisoryOrgInfo,
  actionEditAdvisoryOrgPrevInfo,
  actionChangeToPrevProfileInfo,
  actionChangeProfileDataValidationErrors,
  actionRemoveProfileDataValidationErrors,
  actionChangeProfileMissingDataValidationErrors,
  actionChangeProfileDataValidationSchema,
  actionChangeProfileError,
  actionSetShowUnsavedChangesPrompt,
  actionSetHaveProfileFieldsInnerErrorsObject,
  actionSetProfileFieldsExternalErrorsObject,
  actionEditProfileFieldsExternalErrorsObject,
  actionClearProfileErrors,
  resetState,
} = profileSlice.actions;

export default profileSlice;
